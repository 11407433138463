<template>
  <div>
    <!-- {{goods}} -->
    <v-form ref="addGoodsForm">
      <v-card rounded="0" elevation="0">
        <v-card-title class="text-uppercase font-weight-light grey lighten-5">
          <v-icon large class="mr-2">mdi-dolly</v-icon>{{ tLabel("Goods") }}
          <v-spacer />
          <v-btn depressed :outlined="page!=='INFO'" color="primary" class="mr-2 rounded-0" @click="page='INFO'">
            <v-icon class="mr-2">mdi-information-outline</v-icon>{{ tLabel("Info") }}
          </v-btn>
          <v-btn depressed :outlined="page!=='ITINERARY'" color="primary" class="mr-2 rounded-0" @click="page='ITINERARY'">
            <v-icon class="mr-2">mdi-map-marker-path</v-icon>{{ tLabel("Departure") + " / " + tLabel("Destination") }}
          </v-btn>
        </v-card-title>
        <v-divider class="warning"></v-divider>
        <v-card-text v-show="page === 'INFO'">
          <v-row align="center">
            <v-col cols="4">
              <v-text-field
                dense
                v-model="goods.code"
                :label="tLabelRequired('Code')"
                :rules="[rules.required]"
                clearable
                @input="(val) => (goods.code = val ? val.toUpperCase() : null)"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                dense
                :label="tLabelRequired('Type')"
                v-model="goodsType"
                @change="onChangeGoodsType"
                :items="goodsTypeCodes"
                item-text="goodsTypeCode"
                return-object
                :rules="[rules.required]"
                clearable
              />
            </v-col>
            <v-col cols="2">
              <v-select
                dense
                :label="tLabelRequired('Tipo gestione')"
                v-model="goods.countType"
                :items="countTypes"
                :item-text="itemTextCountType"
                item-value="value"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="4">
              {{ descCountType(goods.countType) }}
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="6">
              <v-text-field dense v-model="goods.goods" :label="tLabel('Description')" clearable />
            </v-col>
            <v-col cols="2">
              <v-text-field dense v-if="goods.countType === 'L'" :label="tLabel('Colli')" v-model.number="goods.quantity" :rules="[rules.numeric]" />
              <v-text-field dense v-else :label="tLabelRequired('Colli')" v-model.number="goods.quantity" :rules="[rules.numeric, rules.required]" />
            </v-col>
            <v-col cols="2">
              <v-text-field dense :label="tLabel('Weight')" v-model.number="goods.grossweight" :rules="[rules.numeric]" clearable />
            </v-col>
            <v-col cols="2">
              <v-checkbox dense :label="tLabel('Dangerous')" v-model="goods.dangerous" />
            </v-col>
          </v-row>
          <v-divider class="my-6" />
          <v-row align="center">
            <v-col cols="3">
              <v-combobox
                dense
                :label="tLabel('Order')"
                v-model="goods.orderCode"
                :items="orders"
                item-value="reference"
                item-text="reference"
                @change="onChangeOrderCode"
                :filter="filterOrders"
                return-object
                clearable
              >
                <template v-slot:item="{ item }">
                  <v-row>
                    <v-col cols="8" class="font-weight-bold blue--text">
                      {{ item.reference }} <span class="text-subtitle-1 font-weight-light pl-4">{{ item.extCode }}</span>
                    </v-col>
                    <v-col>
                      {{ item.type }}
                    </v-col>
                  </v-row>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="3">
              <v-select dense :label="tLabel('Operation')" v-model="goods.orderOperation" :items="orderOperations" item-text="id" item-value="id" clearable>
                <template v-slot:item="{ item }">
                  <v-row>
                    <v-col cols="12" class="font-weight-bold blue--text">
                      {{ item.id }}
                      <span class="text-subtitle-1 font-weight-light pl-4" v-if="item.unit && item.unit !== null">{{ item.unit.code }}</span>
                      <span class="text-subtitle-1 font-weight-light pl-4" v-else>{{ tLabel("Unit not yet assigned") | uppercase }}!</span>
                    </v-col>
                  </v-row>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-select dense v-model="goods.cycle" :items="cyclesList" :label="tLabel('Cycle')" />
            </v-col>
            <v-col cols="2">
              <v-autocomplete dense :label="tLabel('Owner')" v-model="goods.ownerCompanyId" :items="owners" item-text="code" item-value="id" />
            </v-col>
            <v-col cols="2">
              <v-select dense :label="tLabelRequired('Customs Status')" v-model="goods.customsStatus" :items="customsStatus" :rules="[rules.required]" />
            </v-col>
            <v-col cols="2">
              <v-text-field dense :label="tLabel('Lot')" v-model="goods.lot" />
            </v-col>
          </v-row>
          <v-row align="end">
            <v-col cols="4">
              <v-text-field dense :label="tLabel('Position')" v-model="goods.positionCode" @input="(val) => (goods.positionCode = val ? val.toUpperCase() : null)" />
            </v-col>
            <v-col cols="8">
              <v-textarea dense :label="tLabel('Remarks')" v-model="goods.remarks" counter maxlength="255" rows="3" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-show="page === 'ITINERARY'" >
          <ItineraryForm dense :itineraryVisit="goods.itineraryVisit" v-if="goods.itineraryVisit" />
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormatsMixins from "../../mixins/FormatsMixins";
import YardEnumMixin from "../../mixins/yard/EnumMixin";
import ItineraryForm from "@/components/yard/ItineraryForm";

export default {
  name: "GoodsComposeForm",
  data() {
    return {
      page: "INFO",
      goodsType: null,
      goodsTypeCodes: [],
      owners: [],
      orders: [],
      defaultCyclesForOrderType: {},
      orderOperations: [],
    };
  },
  props: {
    goods: {
      type: Object,
      required: true,
    },
  },
  components: { ItineraryForm },
  mixins: [FormatsMixins, YardEnumMixin],
  async mounted() {
    this.goods.itineraryVisit = {};
    this.goodsTypeCodes = await this.$api.yardData.goodsTypeCodes();
    this.owners = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/owners");
    this.orders = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "orders/all");
    const parametersList = await this.$api.registryData.getParameters();
    const parametersMap = Object.assign({}, ...parametersList.map((s) => ({ [s.key]: s.value })));
    this.defaultCyclesForOrderType = {
      STUFFING: parametersMap["YARD.UNIT.STUFFING.CYCLE"] || "E",
      STRIPPING: parametersMap["YARD.UNIT.STRIPPING.CYCLE"] || "I",
    };
  },
  computed: {},
  methods: {
    validate() {
      return this.$refs.addGoodsForm.validate();
    },
    initForm() {
      this.$refs.addGoodsForm.resetValidation();
      this.$refs.addGoodsForm.reset();
      Object.keys(this.goods).forEach((attr) => {
        delete this.goods[attr];
      });
      this.goods.itineraryVisit = {};
    },
    setGoodsType(goodsTypeCode) {
      this.goodsType = this.goodsTypeCodes.filter((gtc) => gtc.goodsTypeCode === goodsTypeCode)[0];
    },
    itemTextCountType: (item) => item.text,
    descCountType(countType) {
      return countType ? this.tLabel("descCountType_" + countType) : "";
    },
    onChangeGoodsType(goodsType) {
      if (goodsType && goodsType !== null) {
        console.log("onChangeGoodsType goodsTypeCode countType", goodsType.goodsTypeCode, goodsType.countType);
        this.goods.goodsTypeCode = goodsType.goodsTypeCode;
        this.goods.countType = goodsType.countType;
      } else {
        this.goods.goodsTypeCode = null;
        this.goods.countType = null;
      }
    },
    onChangeOrderCode() {
      const order = this.goods.orderCode;
      console.log("GoodsComposeForm onChangeOrderCode", order);
      this.orderOperations = [];
      this.goods.orderOperation = null;
      if (typeof order == "object") {
        this.orderOperations = JSON.parse(JSON.stringify(order.operations));
        this.goods.orderCode = order.reference;
        const defCycle = this.defaultCyclesForOrderType[order.type];
        if (defCycle) this.goods.cycle = defCycle;
        this.goods.ownerCompanyId = order.companyId;
      }
    },
    filterOrders(item, queryText, itemText) {
      return (
        (item.reference && item.reference !== null && item.reference.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) ||
        (item.extCode && item.extCode !== null && item.extCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      );
    },
  },
};
</script>

<style></style>
