<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="6" class="d-flex flex-row">
        <v-icon x-large class="mr-3">mdi-view-week-outline</v-icon>
        <v-text-field
          height="50"
          class="text-h5"
          v-model="searchFilter"
          :label="tLabel('Unit number, position')"
          clearable
          @input="(val) => (searchFilter = val ? val.toUpperCase() : null)"
        />
      </v-col>

      <v-col cols="2">
        <v-btn large color="primary" @click="searchUnits">
          <v-icon large>mdi-magnify</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="4" class="text-right" v-if="units !== null && units.length > 0">
        <v-btn large outlined fab left color="primary" elevation="3" class="mr-3" :disabled="page === 1" @click="prevPage">
          <v-icon x-large>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="text-h5 font-weight-light" style="vertical-align: middle">{{ page }} / {{ totalPages }}</span>

        <v-btn large outlined fab right color="primary" elevation="3" class="ml-3" :disabled="page === totalPages" @click="nextPage">
          <v-icon x-large>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- <v-row align="center" v-for="unit in units" :key="unit.id">
      <v-col cols="12">
        {{ unit }}
        <v-divider />
      </v-col>
    </v-row> -->
    <v-row align="center" class="py-0 my-0" v-if="units === null"></v-row>
    <v-row align="center" class="py-0 my-0" v-else-if="units.length > 0">
      <v-col cols="4" v-for="unit in units" :key="unit.visitId" class="py-0 my-0">
        <OrderOpUnitInfoCard :unit="unit" @unitSelected="onUnitSelected" :selectable="true" :disabled="isUnitDisabled(unit)" dense hidePositionIcon />
      </v-col>
    </v-row>
    <v-row align="center" class="py-0 my-0" v-else>
      <v-col cols="12" class="text-h5 py-0 my-0"> Nessuna unit trovata. </v-col>
    </v-row>
    <v-row align="center" v-if="unitsToAdd.length > 0" class="mb-1">
      <v-col cols="12" class="grey lighten-4 d-flex flex-row">
          <div v-for="unit in unitsToAdd" :key="unit.visitId">
            <v-hover v-slot="{ hover }" open-delay="80">
              <v-btn :outlined="!hover" large rounded class="px-2 mr-2 text-subtitle-1" color="secondary">
                {{ unit.number }} <v-icon size="30" class="ml-3" @click="removeUnit(unit)" :title="tLabel('Rimuovi')">mdi-close-circle</v-icon>
              </v-btn>
            </v-hover>
          </div>
        </v-col>
    </v-row>
    <v-row align="center" v-else class="mb-1">
      <v-col cols="12" class="grey lighten-4 text-uppercase font-weight-light text-h5 text-left"> 
        {{tLabel("Nessun cargo selezionato.")}}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrderOpUnitInfoCard from "@/components/yard/OrderOpUnitInfoCard";

export default {
  name: "UnitSearchAndSelectionPanel",
  data() {
    return {
      searchFilter: null,
      units: null,
      unitsToAddVisitIds: [],
      page: 1,
      totalPages: 0,
    };
  },
  props: {
    unitsToAdd: {
      type: Array,
      required: true,
    },
  },
  components: { OrderOpUnitInfoCard },
  created() {
    this.$eventBus.$on("initUnitsSearch", () => {
      this.searchFilter = null;
      this.units = null;
      this.unitsToAddVisitIds = [];
      if (this.unitsToAdd.length > 0) {
        for (let i = this.unitsToAdd.length - 1; i >= 0; i--) {
          this.unitsToAdd.splice(i, 1);
        }
      }
      this.page = 1;
      this.totalPages = 0;
    });
  },
  methods: {
    async searchUnits() {
      let res = await this.$api.yardData.getUnitsForSearchAndSelection(this.searchFilter, "PICK-UP", this.page, 6);
      if (res && res !== null) {
        this.units = res.content;
        this.totalPages = res.totalElements > 0 ? Math.ceil(res.totalElements / 6) : 0;
      }
    },
    nextPage() {
      if (this.page < this.totalPages) {
        this.page++;
        this.searchUnits();
      }
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
        this.searchUnits();
      }
    },
    onUnitSelected(unit) {
      this.unitsToAdd.push(unit);
      this.unitsToAddVisitIds.push(unit.visitId);
    },
    isUnitDisabled(unit) {
      return this.unitsToAddVisitIds.includes(unit.visitId);
    },
    removeUnit(unit) {
      var indexVisitIds = this.unitsToAddVisitIds.indexOf(unit.visitId);
      if (indexVisitIds > -1) {
        this.unitsToAddVisitIds.splice(indexVisitIds, 1);
        this.unitsToAdd.splice(indexVisitIds, 1);
      }
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
