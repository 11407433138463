<template>
  <v-container fluid class="px-0 mx-0">
    <v-row align="center" v-if="cargoType === null" class="mt-2">
      <v-col cols="6" class="text-right text-h3 text-uppercase font-weight-light"> tipo ritiro </v-col>
      <v-col cols="6" class="text-left">
        <v-btn :outlined="cargoType === null || cargoType !== 'UNT'" x-large color="primary" @click="setCargoType('UNT')">
          <v-icon x-large class="pr-2">mdi-view-week-outline</v-icon>UNIT
        </v-btn>
        <v-btn :outlined="cargoType === null || cargoType !== 'GOO'" x-large color="primary" class="ml-2" @click="setCargoType('GOO')">
          <v-icon x-large class="pr-2">mdi-dolly</v-icon>MERCE
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" v-if="cargoType === 'UNT'">
      <v-col cols="12">
        <UnitSearchAndSelectionPanel :unitsToAdd="cargoes" />
      </v-col>
    </v-row>
    <v-row align="center" v-if="cargoType === 'GOO'">
      <v-col cols="12">
        <GoodsSearchAndSelectionPanel :goodsToAdd="cargoes" />
      </v-col>
    </v-row>
    <v-row align="center" v-if="cargoType !== null && isCargoTypeChangeable" class="mt-0">
      <v-col cols="12" class="pt-0">
        <v-card rounded="0" elevation="0">
          <v-card-actions class="blue lighten-5">
            <v-btn small color="primary" outlined @click="setCargoType(null)"> <v-icon small class="pr-2">mdi-swap-horizontal</v-icon>{{ tLabel("Cambia tipo cargo") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UnitSearchAndSelectionPanel from "@/components/yard/UnitSearchAndSelectionPanel";
import GoodsSearchAndSelectionPanel from "@/components/yard/GoodsSearchAndSelectionPanel";

export default {
  name: "CargoesSearchAndSelectionPanel",
  data() {
    return {
      isCargoTypeChangeable: false,
      cargoesInitial: [],
    };
  },
  components: { UnitSearchAndSelectionPanel, GoodsSearchAndSelectionPanel },
  emits: ["cargoTypeChanged"],
  props: {
    cargoes: {
      type: Array,
      required: true,
    },
    cargoType: {
      type: String,
      required: false,
      default: null,
    },
  },
  watch: {
    cargoType(newVal, oldVal) {
      // console.log("watch CargoesSearchAndSelectionPanel", newVal, oldVal);
      if (oldVal === null && newVal !== null) {
        this.isCargoTypeChangeable = true;
      }
      if (oldVal !== newVal) {
        if (this.cargoes.length > 0) {
          this.cargoes.splice(0, this.cargoes.length);
        }
        this.cargoesInitial.forEach((ci) => {
          this.cargoes.push(JSON.parse(JSON.stringify(ci)));
        });
      }
    },
  },
  mounted() {
    // console.log("mounted CargoesSearchAndSelectionPanel", this.cargoType);
    this.cargoesInitial = JSON.parse(JSON.stringify(this.cargoes));
    if (this.cargoType === null) {
      this.isCargoTypeChangeable = true;
    }
  },
  methods: {
    setCargoType(type) {
      if (type === "GOO") {
        this.$eventBus.$emit("initGoodsSearch");
      } else if (type === "UNT") {
        this.$eventBus.$emit("initUnitsSearch");
      }
      this.$emit("cargoTypeChanged", type);
    },
  },
};
</script>

<style></style>
