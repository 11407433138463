<template>
  <div>
    <v-container fluid>
      <v-row align="center">
        <v-col cols="6" class="d-flex flex-row">
          <v-icon x-large class="mr-3">mdi-dolly</v-icon>
          <v-text-field height="50" class="text-h5" v-model="searchFilter" :label="tLabel('Goods code, description, position, origin unit')" clearable @input="(val) => (searchFilter = val ? val.toUpperCase() : null)" />
        </v-col>
        <v-col cols="2">
          <!-- :disabled="searchFilter === null || searchFilter.length < 3" -->
          <v-btn large color="primary" @click="searchGoods">
            <v-icon large>mdi-magnify</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="4" class="text-right" v-if="goods !== null && goods.length > 0">
          <v-btn large outlined fab left color="primary" elevation="3" class="mr-3" :disabled="page === 1" @click="prevPage">
            <v-icon x-large>mdi-chevron-left</v-icon>
          </v-btn>

          <span class="text-h5 font-weight-light" style="vertical-align: middle">{{ page }} / {{ totalPages }}</span>

          <v-btn large outlined fab right color="primary" elevation="3" class="ml-3" :disabled="page === totalPages" @click="nextPage">
            <v-icon x-large>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row align="center">
      <v-col cols="12" v-for="goo in goods" :key="goo.visitId"> 
        {{ goo }}
        <v-divider />
      </v-col>     
    </v-row> -->
      <v-row align="center" class="py-0 my-0" v-if="goods === null"></v-row>
      <v-row align="center" class="py-0 my-0" v-else-if="goods.length > 0">
        <v-col cols="4" v-for="goo in goods" :key="goo.visitId" class="py-0 my-0">
          <OrderOpGoodsInfoCard :goods="goo" @goodsSelected="onGoodsSelected" :selectable="true" :disabled="isGoodsDisabled(goo)" dense hidePositionIcon />
        </v-col>
      </v-row>
      <v-row align="center" class="py-0 my-0" v-else>
        <v-col cols="12" class="text-h5 py-0 my-0"> Nessuna merce trovata. </v-col>
      </v-row>
      <v-row align="center" v-if="goodsToAdd.length > 0" class="mb-1">
        <v-col cols="12" class="grey lighten-4 d-flex flex-row">
          <div v-for="goo in goodsToAdd" :key="goo.visitId">
            <v-hover v-slot="{ hover }" open-delay="80">
              <v-btn :outlined="!hover" large rounded class="px-2 mr-2 text-subtitle-1" color="secondary">
                {{ goo.code }} {{ goo.quantity }} <v-icon size="30" class="ml-3" @click="removeGoods(goo)" :title="tLabel('Rimuovi')">mdi-close-circle</v-icon>
              </v-btn>
            </v-hover>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" v-else class="mb-1">
        <v-col cols="12" class="grey lighten-4 text-uppercase font-weight-light text-h5 text-left"> 
          {{tLabel("Nessun cargo selezionato.")}}
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="goodsQuantityDialogVisible" max-width="600">
      <v-card v-if="goodsSelected !== null">
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Quantità merce") }}</v-card-title>
        <v-card-text>
          <v-form ref="goodsQuantityForm">
            <v-container fluid>
              <v-row align="center">
                <v-col cols="6" class="text-right">
                  <v-text-field class="righted-input text-h5" background-color="white" v-model.number="quantity" :rules="[rules.numeric]">
                    <template v-slot:prepend-inner>
                      <v-icon large @click="quantity = null"> mdi-close </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="1" class="text-center text-h5">/</v-col>
                <v-col cols="5" class="text-left text-h5">{{ goodsSelected.quantity }}</v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="goodsQuantityDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="addGoods"><v-icon>mdi-check</v-icon>{{ tLabel("Esegui") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormatsMixins from "../../mixins/FormatsMixins";
import OrderOpGoodsInfoCard from "@/components/yard/OrderOpGoodsInfoCard";

export default {
  name: "GoodsSearchAndSelectionPanel",
  data() {
    return {
      searchFilter: null,
      goods: null,
      goodsToAddVisitIds: [],
      goodsSelected: null,
      quantity: 0,
      page: 1,
      totalPages: 0,
      goodsQuantityDialogVisible: false,
    };
  },
  props: {
    goodsToAdd: {
      type: Array,
      required: true,
    },
  },
  mixins: [FormatsMixins],
  components: { OrderOpGoodsInfoCard },
  created() {
    this.$eventBus.$on("initGoodsSearch", () => {
      this.searchFilter = null;
      this.goods = null;
      this.goodsToAddVisitIds = [];
      this.goodsSelected = null;
      if (this.goodsToAdd.length > 0) {
        for (let i = this.goodsToAdd.length - 1; i >= 0; i--) {
          this.goodsToAdd.splice(i, 1);
        }
      }
      this.page = 1;
      this.totalPages = 0;
    });
  },
  methods: {
    async searchGoods() {
      let res = await this.$api.yardData.getGoodsForSearchAndSelection(this.searchFilter, "PICK-UP", this.page, 6);
      console.log("searchGoods res ----> ", res);
      if (res && res !== null) {
        this.goods = res.content;
        this.totalPages = res.totalElements > 0 ? Math.ceil(res.totalElements / 6) : 0;
      }
    },
    nextPage() {
      if (this.page < this.totalPages) {
        this.page++;
        this.searchGoods();
      }
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
        this.searchGoods();
      }
    },
    onGoodsSelected(goods) {
      this.goodsSelected = JSON.parse(JSON.stringify(goods));
      this.quantity = this.goodsSelected.quantity;
      this.goodsQuantityDialogVisible = true;
    },
    addGoods() {
      const valid = this.quantity !== null && this.quantity !== 0 && this.quantity <= this.goodsSelected.quantity && this.$refs.goodsQuantityForm.validate();
      if (!valid) {
        return;
      }
      this.goodsSelected.quantity = this.quantity;
      this.goodsToAdd.push(this.goodsSelected);
      this.goodsToAddVisitIds.push(this.goodsSelected.visitId);
      this.goodsQuantityDialogVisible = false;
    },
    isGoodsDisabled(goods) {
      return this.goodsToAddVisitIds.includes(goods.visitId);
    },
    removeGoods(goods) {
      var indexVisitIds = this.goodsToAddVisitIds.indexOf(goods.visitId);
      if (indexVisitIds > -1) {
        this.goodsToAddVisitIds.splice(indexVisitIds, 1);
        this.goodsToAdd.splice(indexVisitIds, 1);
      }
    },
  },
};
</script>

<style>
.righted-input input {
  text-align: right !important;
}
</style>
